import { gql } from '@apollo/client';

import { ORGANIZATION_SETTINGS_FRAGMENTS } from '../organization/fragments';

const BASE_PROJECT_INFO_FRAGMENT = gql`
  fragment BaseProjectInfos on Project {
    id
    archivedAt
    complianceTags
    consensusTotCoverage
    createdAt
    description
    inputType
    instructions
    isAnonymized
    jsonInterface
    minConsensusSize
    numberOfAssets
    reviewCoverage
    rights
    title
    useHoneyPot
  }
`;

const BASE_PROJECT_AUTHOR_ORGANIZATION_INFO_FRAGMENT = gql`
  fragment BaseProjectAuthorOrganizationInfos on Organization {
    id
    name
    license {
      type
    }
  }
`;

const BASE_PROJECT_AUTHOR_INFO_FRAGMENT = gql`
  fragment BaseProjectAuthorInfos on User {
    id
    email
    firstname
    lastname
  }
`;

export const PROJECT_FRAGMENTS_WITHOUT_PROPERTIES_AND_ROLES = gql`
  fragment ProjectInfos on Project {
    ...BaseProjectInfos
    author {
      ...BaseProjectAuthorInfos
      organization {
        ...BaseProjectAuthorOrganizationInfos
      }
    }
    consensusMark
    honeypotMark
    numberOfRemainingAssets
    numberOfReviewedAssets
    numberOfSkippedAssets
  }
  ${BASE_PROJECT_AUTHOR_INFO_FRAGMENT}
  ${BASE_PROJECT_AUTHOR_ORGANIZATION_INFO_FRAGMENT}
  ${BASE_PROJECT_INFO_FRAGMENT}
`;

export const PROJECT_STEPS = gql`
  fragment ProjectStepsInfos on ProjectWorkflowStep {
    id
    name
    consensusCoverage
    numberOfExpectedLabelsForConsensus
    order
    stepCoverage
    type
    isActivated
    assignees {
      id
    }
    validationScripts {
      id
      uploadStatus
      url
    }
  }
`;

export const PROJECT_FRAGMENTS_QUALITY_SETTINGS = gql`
  fragment ProjectInfosForQualitySettings on Project {
    id
    consensusMark
    consensusTotCoverage
    enforceStepSeparation
    honeypotMark
    minConsensusSize
    reviewCoverage
    useHoneyPot
    instructions
    useReviewScore
    secondsToLabelBeforeAutoAssign
    canSkipAsset
    canNavigateBetweenAssets
    steps {
      ...ProjectStepsInfos
    }
    enforceStepSeparation
  }
  ${PROJECT_STEPS}
`;

export const PROJECT_FRAGMENTS_INTERFACE_SETTINGS = gql`
  fragment ProjectInfosForInterfaceSettings on Project {
    canSkipAsset
    canNavigateBetweenAssets
    canUseGeneratedShortcut
    id
  }
`;

export const PROJECT_FRAGMENTS_WITHOUT_KPIS = gql`
  fragment ProjectInfosNoKpis on Project {
    ...BaseProjectInfos
    canSkipAsset
    canNavigateBetweenAssets
    canUseGeneratedShortcut
    author {
      ...BaseProjectAuthorInfos
      organizationId
      organization {
        ...BaseProjectAuthorOrganizationInfos
        ...OrganizationSettings
        maximumNumberOfLLMCalls
        numberOfLLMCallsRemaining
      }
    }
  }
  ${BASE_PROJECT_INFO_FRAGMENT}
  ${BASE_PROJECT_AUTHOR_INFO_FRAGMENT}
  ${BASE_PROJECT_AUTHOR_ORGANIZATION_INFO_FRAGMENT}
  ${ORGANIZATION_SETTINGS_FRAGMENTS}
`;

export const PROJECT_ROLES_FRAGMENTS = gql`
  fragment ProjectRoles on Project {
    id
  }
`;

export const PROJECT_FRAGMENTS_ISSUE_STATISTICS = gql`
  fragment ProjectIssuesStatistics on Project {
    id
    numberOfOpenIssues
    numberOfOpenQuestions
    numberOfSolvedIssues
    numberOfSolvedQuestions
  }
`;

export const PROJECT_WORKFLOW_SETTINGS = gql`
  fragment ProjectWorkflowSettings on ProjectWorkflowSettings {
    enforceStepSeparation
    steps {
      ...ProjectStepsInfos
    }
  }
  ${PROJECT_STEPS}
`;

export const PROJECT_AUTHOR_INFORMATION = gql`
  fragment ProjectAuthorInformation on Project {
    author {
      id
      email
      firstname
      lastname
      organizationId
      organization {
        id
        name
        license {
          type
        }
      }
    }
  }
`;
