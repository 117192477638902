import Button from '@kili-technology/button';
import { Typography } from '@material-ui/core';
import _get from 'lodash/get';
import { type FunctionComponent, useEffect, useState } from 'react';

import { useStyles } from './VersionReloadDialog.style';

import { getApiEndpoint } from '../../config';
import { sendToDatadog } from '../../datadog';

const INTERVAL_VERSION_CHECK = 5 * 60_000;

type PropsType = Record<string, unknown>;

const VersionReloadDialog: FunctionComponent<PropsType> = props => {
  const [isShowingDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const versionEndpoint = `${getApiEndpoint()}/version`;
      fetch(versionEndpoint)
        .then(data => data.json())
        .then(data => {
          const frontendVersion = _get(window, '_env_.KILI__VERSION');
          const backendVersion = data?.version;
          if (frontendVersion !== backendVersion && !isShowingDialog) {
            setShowDialog(true);
            sendToDatadog(
              Error(`VERSION: front: ${frontendVersion}, back: ${backendVersion}`),
              null,
              'version',
            );
          }
        })
        .catch(error => sendToDatadog(error, null, 'version'));
    }, INTERVAL_VERSION_CHECK);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles(props);
  if (!isShowingDialog) return null;
  return (
    <div className={classes.root}>
      <Typography>
        A new version of Kili is available, please reload this page. You can find the
        changelog&nbsp;
        <a href="https://docs.kili-technology.com/changelog/" rel="noreferrer" target="_blank">
          here
        </a>
        .
      </Typography>

      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <Button onClick={() => window.location.reload(true)} variant="secondary">
        Reload
      </Button>
    </div>
  );
};

export default VersionReloadDialog;
