import { cx } from '@emotion/css';
import { Check, Suspend } from '@kili-technology/icons';
import { Box, CircularProgress, IconButton, MenuItem } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { type WithStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import { t } from 'i18next';
import { type FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationStatus } from '@/__generated__/globalTypes';
import { sendToDatadog } from '@/datadog';
import { addErrorNotification } from '@/redux/application/actions';
import { downloadBucketSignedExportUrl } from '@/services/assets/download';

import { type styles } from './NotificationRow.style';

export type NotificationRowProps = WithStyles<typeof styles> & {
  message: string;
  progress: number | null;
  status: string;
  url: string;
};

const NotificationRow: FunctionComponent<NotificationRowProps> = ({
  classes,
  message,
  status,
  progress,
  url,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();

  const download = async () => {
    setIsDownloading(true);

    try {
      await downloadBucketSignedExportUrl(url);
    } catch (error) {
      sendToDatadog(error instanceof Error ? error : new Error('Unknown error'), url, 'network');
      dispatch(addErrorNotification(t('general.notification.errorOccurred')));
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <MenuItem className={classes.menuItem} data-cy="cypress-notification" disableRipple>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          {status === NotificationStatus.FAILURE && (
            <Suspend className={classes.failureIcon} data-cy="notification-failure" />
          )}
          {status === NotificationStatus.PENDING && (
            <CircularProgress data-cy="notification-pending" size={18} />
          )}
          {status === NotificationStatus.SUCCESS && (
            <Check className={classes.successIcon} data-cy="notification-success" />
          )}
        </div>
        <p className={cx(classes.message, 'menu')} data-cy="notification-message">
          {message}
        </p>
        {url && (
          <IconButton
            data-cy="download-button"
            data-testid="download-button"
            disabled={isDownloading}
            disableRipple
            onClick={download}
            size="small"
          >
            {isDownloading ? (
              <CircularProgress size={18} style={{ margin: 3 }} />
            ) : (
              <DownloadIcon />
            )}
          </IconButton>
        )}
      </div>
      {status === NotificationStatus.PENDING && progress !== null && (
        <Box alignItems="center" display="flex" height={35} width="100%">
          <Box mr={1} width="100%">
            <LinearProgress className={classes.progress} value={progress} variant="determinate" />
          </Box>
          <Box minWidth={30}>
            <p className="body-2">{`${Math.round(progress)}%`}</p>
          </Box>
        </Box>
      )}
    </MenuItem>
  );
};

export default NotificationRow;
