import { Tool } from '@kili-technology/types';
import get from 'lodash/get';

import {
  defaultPoseEstimationSettings,
  defaultPropagationSettings,
  defaultRelationSettings,
  defaultTagSettings,
  defaultToolThenClassSettings,
  defaultTimelineSettings,
  getLabelInterfaceSettingsKey,
} from './helpers';

import { type ZustandStore } from '..';
import { decodeToolNameAsTool } from '../../components/asset-ui/Image/LeafletMapv2/constant';
import { type ToolName } from '../../components/asset-ui/Image/LeafletMapv2/type';

export const selectLabelInterfaceSettings =
  (projectId: string, userId: string) => (state: ZustandStore) => {
    if (projectId && userId) {
      const {
        labelInterface: { settings },
      } = state;

      return settings[getLabelInterfaceSettingsKey({ projectId, userId })] || null;
    }
    return null;
  };

export const selectLabelInterfaceTagSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.tag || defaultTagSettings;

export const selectLabelInterfaceShowConfidenceScoreTags =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceTagSettings(projectId, userId)(state).showConfidence;

export const selectLabelInterfaceRelationSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.relation || defaultRelationSettings;

export const selectLabelInterfaceToolThenClassSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.toolThenClass ||
    defaultToolThenClassSettings;

export const selectLabelInterfacePoseEstimationSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.poseEstimation ||
    defaultPoseEstimationSettings;

export const selectLabelInterfacePropagationSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.propagation ||
    defaultPropagationSettings;

export const selectLabelInterfaceTimelineSettings =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.timeline || defaultTimelineSettings;

export const selectLabelInterfaceInteractiveSegmentationModel =
  (projectId: string, userId: string) => (state: ZustandStore) =>
    selectLabelInterfaceSettings(projectId, userId)(state)?.interactiveSegmentation;

export const selectToolOptions = (name: ToolName) => (state: ZustandStore) => {
  const tool = state.labelInterface.grantedTools[name];
  return {
    isSelected: tool?.isSelected || false,
    isVisible: tool?.isVisible || false,
  };
};

export const selectIsADrawToolSelected = (state: ZustandStore) => {
  const { isSelected: isPolygonToolSelected } = selectToolOptions('Polygon')(state);
  const { isSelected: isSegmentationToolSelected } = selectToolOptions('Segmentation')(state);
  const { isSelected: isBoundingBoxToolSelected } = selectToolOptions('BoundingBox')(state);
  const { isSelected: isLineToolSelected } = selectToolOptions('Line')(state);
  const { isSelected: isPointToolSelected } = selectToolOptions('Point')(state);
  const { isSelected: isPoseEstimationToolSelected } = selectToolOptions('PoseEstimation')(state);
  const { isSelected: isSemanticToolSelected } = selectToolOptions('Semantic')(state);

  const isAToolSelected =
    isPolygonToolSelected ||
    isSegmentationToolSelected ||
    isBoundingBoxToolSelected ||
    isLineToolSelected ||
    isPointToolSelected ||
    isPoseEstimationToolSelected ||
    isSemanticToolSelected;

  return isAToolSelected;
};

const priorityTools = [
  Tool.RECTANGLE,
  Tool.POLYGON,
  Tool.POLYLINE,
  Tool.MARKER,
  Tool.POSE,
  Tool.SEMANTIC,
  Tool.VECTOR,
  Tool.RANGE,
];

export const selectTypeFromActiveTools = (state: ZustandStore) => {
  const toolNames = Object.keys(
    state.labelInterface.grantedTools,
  ) as (keyof typeof state.labelInterface.grantedTools)[];
  const activeToolsName = toolNames
    .map(toolName => ({ name: toolName, tool: state.labelInterface.grantedTools[toolName] }))
    .filter(t => t.tool?.isSelected && t.tool.isVisible)
    .map(t => decodeToolNameAsTool(t.name));

  for (const tool of priorityTools) {
    if (activeToolsName.includes(tool)) return tool;
  }

  return undefined;
};

export const selectIsExpanded = (path: string[]) => (state: ZustandStore) =>
  get(state.labelInterface.accordionTree, path.concat('value'), false);

export const selectShouldActivateShortcuts =
  (parentMid: string | undefined, path: string[]) => (state: ZustandStore) => {
    if (!parentMid) return true;

    const index = path.findIndex(s => s === parentMid);
    if (index === -1) return true;

    const { accordionTree } = state.labelInterface;
    const categoriesAccordion = get(accordionTree, path.slice(0, index));
    const displayed = Object.keys(categoriesAccordion).filter(key =>
      Boolean(categoriesAccordion[key]?.value),
    );
    return displayed.length === 1;
  };
