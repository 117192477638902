import { type Draft } from 'immer';

import { type PyodideSlice, type PyodideValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: PyodideValues = {
  isLoading: false,
  isReady: false,
  librariesLoaded: false,
};

const log = createLogger('labelSave');

export const createPyodideSlice: ZustandSlice<PyodideSlice> = set => ({
  ...initialState,
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.pyodide as Draft<PyodideValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';
