export const NUMBER_OF_RETRY = 5;
export const DELAY_BETWEEN_RETRY = 1000;
export const IMAGE_LOAD_TIMEOUT = 20000;

export const BLACK_LISTED_PARAMETERS = [
  'token',
  'X-Goog-Algorithm',
  'X-Goog-Credential',
  'X-Goog-Date',
  'X-Goog-Expires',
  'X-Goog-SignedHeaders',
  'X-Goog-Signature',
  'X-Amz-Algorithm',
  'X-Amz-Content-Sha256',
  'X-Amz-Credential',
  'X-Amz-Date',
  'X-Amz-Expires',
  'X-Amz-Signature',
  'X-Amz-SignedHeaders',
];
