/* eslint-disable @typescript-eslint/naming-convention */

const DEVELOPMENT_FLAGS = {
  com_project_creation_limits: () => false,
  config_event_segment: () => false,
  lab_block_multi_review: () => false,
  lab_custom_validation: () => true,
  lab_multi_tabs: () => true,
  lab_prevent_right_click: () => false,
  lab_synchronize_video_from_bucket: () => true,
  lab_video_konvas_v1: (email: string) => email.includes('test+admin+konvas@kili-technology.com'),
  lops_auth_email_verification: () => true,
};

export default DEVELOPMENT_FLAGS;
