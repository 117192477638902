import {
  type Annotation,
  type AnnotationCategory as Category,
  type EntityAnnotation,
  type EntityRelation,
  type JobAnnotation,
  type JobResponse,
  type JsonCategories as Categories,
  type JsonResponse,
  type Job,
  type Jobs,
  MachineLearningTask,
  type ObjectRelation,
  type PoseEstimationAnnotation,
  type PoseEstimationMarker,
  type TranscriptionAnnotation,
  type TranscriptionAtPageLevelAnnotation,
} from '@kili-technology/types';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import usePagination from '@/components/asset-ui/Pdf/PaginationToolbar/PaginationStore';

import {
  ANNOTATIONS,
  CATEGORIES,
  CHILDREN,
  CODE,
  CONTENT,
  END_ENTITIES,
  IS_CHILD,
  MANDATORY,
  MID,
  ML_TASK,
  NAME,
  POINTS,
  START_ENTITIES,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { generateDefaultJobInstruction } from '../../pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobRenderer/helpers';
import { type VisibilityTree } from '../../redux/application/types';
import { type PageLevelWrapper, type Responses } from '../../redux/jobs/types';
import { getJobNamesWithoutInstructionGroupedByMlTask } from '../../redux/project/selectors';
import { getLabelStatusFromJob, LabelStatus } from '../jsonResponse/shouldAllowNext';
import { MID_SPLIT_POINT_STRING } from '../poseEstimation';

export const ASSET = 'asset';
export const JOBS = 'jobs';

enum AllBelowStatus {
  CHECK_COMPLETION = 'CHECK_COMPLETION',
  DISPLAY = 'DISPLAY',
  HIDE = 'HIDE',
  SEARCH = 'SEARCH',
  SEARCH_AND_CHECK_COMPLETION = 'SEARCH_AND_CHECK_COMPLETION',
}

const valueIsInString = (string: string, value: string) =>
  string.toLowerCase().includes(value.toLowerCase());

const isFilteredInByTranscription = (
  jobResponse: TranscriptionAnnotation,
  searchBarValue: string,
  allBelow: AllBelowStatus,
): boolean => {
  if (allBelow === AllBelowStatus.DISPLAY) {
    return true;
  }
  if (allBelow === AllBelowStatus.HIDE) {
    return false;
  }
  const answer: string = _get(jobResponse, 'text', '');
  return valueIsInString(answer, searchBarValue);
};

const categoryFilteredInByName = (
  jobCategories: Categories,
  category: string,
  searchBarValue: string,
): boolean => {
  const name: string = _get(jobCategories, [category, NAME], '');
  return valueIsInString(name, searchBarValue);
};

const pointFilteredByName = (pointName: string, searchBarValue: string): boolean =>
  valueIsInString(pointName, searchBarValue);

const isJobFilteredInByInstruction = (
  job: [string, Job],
  searchBarValue: string,
  jobNamesWithoutInstructionGroupedByMlTaskValue: Record<MachineLearningTask, string[]>,
): boolean => {
  const jobName = job[0];
  const jobContent = job[1];
  const firstTool = jobContent?.tools?.[0];
  const { mlTask } = jobContent;
  const instruction =
    jobContent?.instruction ||
    generateDefaultJobInstruction(
      jobName,
      mlTask,
      firstTool,
      jobNamesWithoutInstructionGroupedByMlTaskValue,
    );
  return valueIsInString(instruction, searchBarValue);
};

const isJobFilteredIn = (
  jobs: Jobs,
  responses: Responses,
  searchBarValue: string,
  jobName: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  shouldHandleObjectTaskAsClassification: boolean,
  jobNamesWithoutInstructionGroupedByMlTaskValue: Record<MachineLearningTask, string[]>,
  jobResponseWithChildren: JobResponse,
  parentMid: string | undefined = undefined,
): boolean => {
  const job = jobs[jobName];
  const mlTask = _get(job, ML_TASK, '');

  let modifiedAllBelow = allBelow;

  if (
    modifiedAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION ||
    modifiedAllBelow === AllBelowStatus.CHECK_COMPLETION
  ) {
    if (jobResponseWithChildren) {
      const jobFilteredOut =
        getLabelStatusFromJob({
          allJobs: jobs,
          job: jobs[jobName],
          response: jobResponseWithChildren,
        }) === LabelStatus.SUCCESS;
      if (jobFilteredOut) {
        modifiedAllBelow = AllBelowStatus.HIDE;
      }
    }
  }

  if (
    modifiedAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION ||
    modifiedAllBelow === AllBelowStatus.SEARCH
  ) {
    const jobFilteredInByInstruction = isJobFilteredInByInstruction(
      [jobName, job],
      searchBarValue,
      jobNamesWithoutInstructionGroupedByMlTaskValue,
    );

    if (jobFilteredInByInstruction) {
      if (modifiedAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION) {
        modifiedAllBelow = AllBelowStatus.CHECK_COMPLETION;
      } else {
        modifiedAllBelow = AllBelowStatus.DISPLAY;
      }
    }
  }

  const jobFilteredIn = isJobFilteredInByJobContent(
    jobs,
    responses,
    jobName,
    mlTask,
    searchBarValue,
    isVisible,
    modifiedAllBelow,
    shouldHandleObjectTaskAsClassification,
    jobNamesWithoutInstructionGroupedByMlTaskValue,
    jobResponseWithChildren,
    parentMid,
  );

  if (parentMid) {
    // eslint-disable-next-line no-param-reassign
    isVisible.Jobs[`${parentMid}.${jobName}`] = jobFilteredIn;
  } else {
    // eslint-disable-next-line no-param-reassign
    isVisible.Jobs[jobName] = _get(isVisible.Jobs, jobName) ? true : jobFilteredIn;
  }

  return jobFilteredIn;
};

const isJobFilteredInByChildren = (
  jobs: Jobs,
  responses: Responses,
  searchBarValue: string,
  children: string[],
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  shouldHandleObjectTaskAsClassification: boolean,
  childrenJobsResponse: JsonResponse,
  parentMid: string | undefined = undefined,
): boolean => {
  return children
    .map(childJobName => {
      return isJobFilteredIn(
        jobs,
        responses,
        searchBarValue,
        childJobName,
        isVisible,
        allBelow,
        shouldHandleObjectTaskAsClassification,
        jobNamesGroupedByMlTask,
        childrenJobsResponse?.[childJobName] ?? {},
        parentMid,
      );
    })
    .includes(true);
};

const isJobFilteredInByJobContent = (
  jobs: Jobs,
  responses: Responses,
  jobName: string,
  mlTask: string,
  searchBarValue: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  shouldHandleObjectTaskAsClassification: boolean,
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  jobResponseWithChildren: JobResponse,
  parentMid: string | undefined = undefined,
): boolean => {
  const job = jobs[jobName];

  // Here if the jobResponse that comes from the jobsResponse exists we take it as it contains the children,
  // which is not the case for the one coming from responses
  const jobResponse = _get(responses, `${mlTask}.${jobName}`, {});
  if (mlTask === MachineLearningTask.TRANSCRIPTION) {
    return isFilteredInByTranscription(
      jobResponse as TranscriptionAnnotation,
      searchBarValue,
      allBelow,
    );
  }
  if (mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION) {
    const { currentPage } = usePagination.getState();
    return isFilteredInByTranscription(
      (jobResponse as PageLevelWrapper<TranscriptionAtPageLevelAnnotation>).byId?.[currentPage] ??
        {},
      searchBarValue,
      allBelow,
    );
  }
  const jobCategories = _get(job, [CONTENT, CATEGORIES], {});
  const entityAnnotations: EntityAnnotation[] = [];
  Object.keys(_get(responses, MachineLearningTask.NAMED_ENTITIES_RECOGNITION, {})).forEach(
    jobNameKey =>
      entityAnnotations.push(
        ..._get(
          responses,
          [MachineLearningTask.NAMED_ENTITIES_RECOGNITION, jobNameKey, ANNOTATIONS],
          [],
        ),
      ),
    [],
  );

  return isFilteredInByClassification(
    jobs,
    responses,
    job,
    jobName,
    jobResponse as JobResponse,
    searchBarValue,
    isVisible,
    allBelow,
    jobCategories,
    jobNamesGroupedByMlTask,
    shouldHandleObjectTaskAsClassification,
    jobResponseWithChildren,
    parentMid,
    entityAnnotations,
  );
};

const isAnnotationFilteredInByContent = (
  mlTask: MachineLearningTask | null,
  annotation: Annotation,
  searchBarValue: string,
  entityAnnotations?: EntityAnnotation[],
): boolean => {
  switch (mlTask) {
    case MachineLearningTask.NAMED_ENTITIES_RECOGNITION: {
      const content = _get(annotation, CONTENT, '');
      return valueIsInString(content, searchBarValue);
    }
    case MachineLearningTask.NAMED_ENTITIES_RELATION: {
      const startEntities = _get(annotation, START_ENTITIES, []) as string[];
      const endEntities = _get(annotation, END_ENTITIES, []) as string[];
      if (!entityAnnotations) {
        return false;
      }
      const contents = startEntities.concat(endEntities).map(entity => {
        const mid = _get(entity, MID, '');
        const correspondingEntity = entityAnnotations.filter(e => _get(e, MID, '') === mid);
        return _get(correspondingEntity, `[0].${CONTENT}`, '');
      });
      const stringAnnotation = contents.join('---');
      return valueIsInString(stringAnnotation, searchBarValue);
    }
    default:
      return false;
  }
};

const isAnnotationOrPointFilteredInByMid = ({ mid, search }: { mid: string; search: string }) =>
  search === mid;

const isCategoryFilteredInByAnnotation = (
  jobs: Jobs,
  responses: Responses,
  job: Job,
  classAnnotations: Annotation[],
  category: string,
  searchBarValue: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  mlTask: MachineLearningTask | null,
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  shouldHandleObjectTaskAsClassification: boolean,
  jobResponseWithChildren: JobResponse,
  entityAnnotations?: EntityAnnotation[],
): boolean => {
  const categoryFilteredInByAnnotation = classAnnotations
    .map(annotation => {
      const children = _get(job, [CONTENT, CATEGORIES, category, CHILDREN], []);
      const points = _get(job, [CONTENT, CATEGORIES, category, POINTS], []);

      const mid = _get(annotation, MID, '');
      const responsesAnnotation = _get(responses, mid, {}) as Responses;

      let annotationAllBelow = allBelow;

      if (
        annotationAllBelow === AllBelowStatus.SEARCH ||
        annotationAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
      ) {
        const annotationFilteredInByContent = isAnnotationFilteredInByContent(
          mlTask,
          annotation,
          searchBarValue,
          entityAnnotations,
        );
        const annotationFilteredInByMid = isAnnotationOrPointFilteredInByMid({
          mid,
          search: searchBarValue,
        });
        if (annotationFilteredInByContent || annotationFilteredInByMid) {
          if (annotationAllBelow === AllBelowStatus.SEARCH) {
            annotationAllBelow = AllBelowStatus.DISPLAY;
          } else {
            annotationAllBelow = AllBelowStatus.CHECK_COMPLETION;
          }
        }
      }

      let annotationFilteredIn = false;

      if (!isEmpty(children) || !isEmpty(points)) {
        let annotationFromJobResponseWithChildren;
        if (
          annotationAllBelow === AllBelowStatus.CHECK_COMPLETION ||
          annotationAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
        ) {
          const allAnnotationsFromJobResponseWithChildren = _get(
            jobResponseWithChildren,
            [ANNOTATIONS],
            [],
          ) as Annotation[];
          annotationFromJobResponseWithChildren = allAnnotationsFromJobResponseWithChildren.find(
            a => a.mid === mid,
          );
        }

        if (!isEmpty(children)) {
          let childrenJsonResponse = {};

          if (
            annotationAllBelow === AllBelowStatus.CHECK_COMPLETION ||
            annotationAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
          ) {
            childrenJsonResponse = _get(annotationFromJobResponseWithChildren, [CHILDREN], {});
          }

          const annotationFilteredInByChildren = isJobFilteredInByChildren(
            jobs,
            responsesAnnotation,
            searchBarValue,
            children,
            isVisible,
            annotationAllBelow,
            jobNamesGroupedByMlTask,
            shouldHandleObjectTaskAsClassification,
            childrenJsonResponse,
            mid,
          );
          annotationFilteredIn = annotationFilteredIn || annotationFilteredInByChildren;
        }

        if (!isEmpty(points)) {
          // First check if the point is filtered by Name

          const pointsJobResponsesWithChildren = _get(
            annotationFromJobResponseWithChildren,
            [POINTS],
            [] as PoseEstimationMarker[],
          );

          const annotationFilteredInByPoints = isCategoryFilteredInByPoints(
            jobs,
            points,
            responses,
            searchBarValue,
            isVisible,
            annotationAllBelow,
            pointsJobResponsesWithChildren,
            jobNamesGroupedByMlTask,
            shouldHandleObjectTaskAsClassification,
            mid,
          );
          annotationFilteredIn = annotationFilteredIn || annotationFilteredInByPoints;
        }
      } else if (annotationAllBelow === AllBelowStatus.DISPLAY) {
        annotationFilteredIn = true;
      } else {
        annotationFilteredIn = false;
      }

      if (annotationAllBelow === AllBelowStatus.CHECK_COMPLETION) {
        if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
          const namedEntityRelationAnnotation = annotation as EntityRelation;
          annotationFilteredIn =
            annotationFilteredIn ||
            isEmpty(namedEntityRelationAnnotation.startEntities) ||
            isEmpty(namedEntityRelationAnnotation.endEntities);
        }
        if (mlTask === MachineLearningTask.OBJECT_RELATION) {
          const objectRelationAnnotation = annotation as ObjectRelation;
          annotationFilteredIn =
            annotationFilteredIn ||
            isEmpty(objectRelationAnnotation.startObjects) ||
            isEmpty(objectRelationAnnotation.endObjects);
        }
      }

      // eslint-disable-next-line no-param-reassign
      isVisible.Objects[mid] = annotationFilteredIn;
      return annotationFilteredIn;
    })
    .includes(true);

  return categoryFilteredInByAnnotation;
};

const isCategoryFilteredInByPoints = (
  jobs: Jobs,
  poseEstimationAnnotations: PoseEstimationAnnotation[],
  responses: Responses,
  searchBarValue: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  pointsJobResponsesWithChildren: PoseEstimationMarker[],
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  shouldHandleObjectTaskAsClassification: boolean,
  midObject: string,
): boolean => {
  const categoryFilteredInByPoints = poseEstimationAnnotations
    .map(point => {
      const pointName = _get(point, NAME, '');
      const pointCode = _get(point, CODE, '');
      const midPointFromObject = `${midObject}${MID_SPLIT_POINT_STRING}${pointCode}`;

      const pointIsMandatory = _get(point, MANDATORY, false);
      const children = _get(point, CHILDREN, []) as string[];
      const responsesPoint = _get(responses, midPointFromObject, {}) as Responses;
      const pointJobResponseWithChildren = pointsJobResponsesWithChildren.find(
        pointResponse => pointResponse.code === pointCode,
      );
      const childrenJsonResponse = _get(pointJobResponseWithChildren, CHILDREN, {});

      let pointAllBelow = allBelow;

      if (
        pointAllBelow === AllBelowStatus.SEARCH ||
        pointAllBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
      ) {
        const pointFilteredInByName = pointFilteredByName(pointName, searchBarValue);

        if (pointFilteredInByName) {
          if (pointAllBelow === AllBelowStatus.SEARCH) {
            pointAllBelow = AllBelowStatus.DISPLAY;
          } else {
            pointAllBelow = AllBelowStatus.CHECK_COMPLETION;
          }
        }
      }

      let pointFilteredIn;

      if (
        pointAllBelow === AllBelowStatus.CHECK_COMPLETION &&
        !pointIsMandatory &&
        pointJobResponseWithChildren === undefined
      ) {
        pointFilteredIn = false;
      } else if (!isEmpty(children)) {
        pointFilteredIn = isJobFilteredInByChildren(
          jobs,
          responsesPoint,
          searchBarValue,
          children,
          isVisible,
          pointAllBelow,
          jobNamesGroupedByMlTask,
          shouldHandleObjectTaskAsClassification,
          childrenJsonResponse,
          midPointFromObject,
        );
      } else if (pointAllBelow === AllBelowStatus.DISPLAY) {
        pointFilteredIn = true;
      } else if (
        pointAllBelow === AllBelowStatus.CHECK_COMPLETION &&
        pointJobResponseWithChildren === undefined &&
        pointIsMandatory
      ) {
        pointFilteredIn = true;
      } else {
        pointFilteredIn = false;
      }

      // eslint-disable-next-line no-param-reassign
      isVisible.Points[midPointFromObject] = pointFilteredIn;
      return pointFilteredIn;
    })
    .includes(true);

  return categoryFilteredInByPoints;
};

const isFilteredInByResponse = (
  jobs: Jobs,
  responses: Responses,
  job: Job,
  jobName: string,
  jobResponse: JobResponse,
  searchBarValue: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  jobCategories: Categories,
  category: string,
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  shouldHandleObjectTaskAsClassification: boolean,
  jobResponseWithChildren: JobResponse,
  parentMid: string | undefined,
  entityAnnotations?: EntityAnnotation[],
): boolean => {
  const mlTask = _get(job, ML_TASK, null);
  if (mlTask === MachineLearningTask.CLASSIFICATION || shouldHandleObjectTaskAsClassification) {
    const children = _get(jobCategories, [category, CHILDREN], [] as string[]);

    let categoryFilteredIn;

    const categoriesFromJobResponseWithChildren = _get(
      jobResponseWithChildren,
      [CATEGORIES],
      [],
    ) as Category[];
    if (!isEmpty(children)) {
      const categoryFromJobResponseWithChildren = categoriesFromJobResponseWithChildren.find(
        cat => cat.name === category,
      );
      const childrenJsonResponse = _get(categoryFromJobResponseWithChildren, [CHILDREN], {});

      categoryFilteredIn = isJobFilteredInByChildren(
        jobs,
        responses,
        searchBarValue,
        children,
        isVisible,
        allBelow,
        jobNamesGroupedByMlTask,
        shouldHandleObjectTaskAsClassification,
        childrenJsonResponse,
        parentMid,
      );

      // In case we are in a CHECK_COMPLETION mode we should check that the jobResonse is not empty
      // because in that case we would want to keep a category even if its subjobs are not required
      if (
        allBelow === AllBelowStatus.CHECK_COMPLETION &&
        !categoryFilteredIn &&
        (isEmpty(jobResponseWithChildren) || isEmpty(categoriesFromJobResponseWithChildren))
      ) {
        categoryFilteredIn = true;
      }
    } else if (allBelow === AllBelowStatus.DISPLAY) {
      categoryFilteredIn = true;
    } else if (allBelow === AllBelowStatus.CHECK_COMPLETION) {
      // Here it is a bit similar we let the category visible only if the jobResponse is empty to let
      // the user make his choice
      categoryFilteredIn =
        isEmpty(jobResponseWithChildren) || isEmpty(categoriesFromJobResponseWithChildren);
    } else {
      categoryFilteredIn = false;
    }

    if (parentMid) {
      // eslint-disable-next-line no-param-reassign
      isVisible.Classes[`${parentMid}.${jobName}.${category}`] = categoryFilteredIn;
    } else {
      // eslint-disable-next-line no-param-reassign
      isVisible.Classes[`${jobName}.${category}`] = categoryFilteredIn;
    }

    return categoryFilteredIn;
  }

  const annotations = _get(jobResponse as JobAnnotation, ANNOTATIONS, [] as Annotation[]);
  const classAnnotations = annotations.filter(
    annotation => _get(annotation, `${CATEGORIES}.[0].${NAME}`, '') === category,
  );

  let categoryFilteredIn;
  if (!isEmpty(classAnnotations)) {
    categoryFilteredIn = isCategoryFilteredInByAnnotation(
      jobs,
      responses,
      job,
      classAnnotations,
      category,
      searchBarValue,
      isVisible,
      allBelow,
      mlTask,
      jobNamesGroupedByMlTask,
      shouldHandleObjectTaskAsClassification,
      jobResponseWithChildren,
      entityAnnotations,
    );
  } else if (allBelow === AllBelowStatus.DISPLAY || allBelow === AllBelowStatus.CHECK_COMPLETION) {
    categoryFilteredIn = true;
  } else {
    categoryFilteredIn = false;
  }

  // eslint-disable-next-line no-param-reassign
  isVisible.Classes[`${jobName}.${category}`] = categoryFilteredIn;

  return categoryFilteredIn;
};

const isFilteredInByClassification = (
  jobs: Jobs,
  responses: Responses,
  job: Job,
  jobName: string,
  jobResponse: JobResponse,
  searchBarValue: string,
  isVisible: VisibilityTree,
  allBelow: AllBelowStatus,
  jobCategories: Categories,
  jobNamesGroupedByMlTask: Record<MachineLearningTask, string[]>,
  shouldHandleObjectTaskAsClassification: boolean,
  jobResponseWithChildren: JobResponse,
  parentMid: string | undefined,
  entityAnnotations?: EntityAnnotation[],
): boolean => {
  const jobCategoryCodes = Object.keys(jobCategories);
  return jobCategoryCodes
    .map(category => {
      let modifiedAllBelow = allBelow;
      if (
        allBelow === AllBelowStatus.SEARCH ||
        allBelow === AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
      ) {
        const categoryNameFilteredIn = categoryFilteredInByName(
          jobCategories,
          category,
          searchBarValue,
        );
        if (categoryNameFilteredIn) {
          if (allBelow === AllBelowStatus.SEARCH) {
            modifiedAllBelow = AllBelowStatus.DISPLAY;
          } else {
            modifiedAllBelow = AllBelowStatus.CHECK_COMPLETION;
          }
        }
      }
      const categoryFilteredIn = isFilteredInByResponse(
        jobs,
        responses,
        job,
        jobName,
        jobResponse,
        searchBarValue,
        isVisible,
        modifiedAllBelow,
        jobCategories,
        category,
        jobNamesGroupedByMlTask,
        shouldHandleObjectTaskAsClassification,
        jobResponseWithChildren,
        parentMid,
        entityAnnotations,
      );
      return categoryFilteredIn;
    })
    .includes(true);
};

export const computeVisibilityTree = (
  jobs: Jobs,
  responses: Responses,
  searchBarValue: string,
  shouldShowOnlyMissingJobs: boolean,
  shouldHandleObjectTaskAsClassification?: boolean,
  jobsResponse?: JsonResponse,
): VisibilityTree => {
  const jobNamesGroupedByMlTask = getJobNamesWithoutInstructionGroupedByMlTask(jobs);
  const isVisible = {
    Classes: {},
    Jobs: {},
    Objects: {},
    Points: {},
  } as VisibilityTree;

  Object.keys(jobs)
    .filter(jobName => !_get(jobs[jobName], IS_CHILD, true) && !jobs[jobName].isModel)
    .forEach(jobName => {
      const jobResponseWithChildren = jobsResponse?.[jobName] ?? ({} as JobResponse);
      const isParentVisible = isJobFilteredIn(
        jobs,
        responses,
        searchBarValue,
        jobName,
        isVisible,
        shouldShowOnlyMissingJobs
          ? AllBelowStatus.SEARCH_AND_CHECK_COMPLETION
          : AllBelowStatus.SEARCH,
        shouldHandleObjectTaskAsClassification || false,
        jobNamesGroupedByMlTask,
        jobResponseWithChildren,
      );
      isVisible.Jobs[jobName] = isParentVisible;
    });

  return isVisible;
};
