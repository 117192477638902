import {
  type AnnotationCategory,
  type EntityRelation,
  type ImageBoundingPoly,
  type ImageModel,
  Input,
  type Job,
  type Jobs,
  type JsonCategory as Category,
  type JsonResponse,
  MachineLearningTask,
  ModelName,
  type ObjectAnnotation2D,
  type ObjectRelation,
  type PoseEstimationPoint,
} from '@kili-technology/types';
import { type Action, type ThunkDispatch } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import { batch } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import { InputType, type LabelType } from '@/__generated__/globalTypes';
import { ANY_RELATION_VALUE } from '@/components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import { RelationObjectType } from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { changeAnnotationsClassVideoSplitWithHistory } from '@/graphql/annotations/helpers/changeClass';
import { isClassificationCategoryChecked } from '@/pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobCategory/selectors';
import { rotateAnnotationByAngle } from '@/services/assets/rotation';
import { getCategoryCodeFromAnnotation } from '@/services/jobs/categories';
import { getAllowedObjects } from '@/services/jobs/objectTasks';
import { getResponsesToSet, type KiliAnnotation } from '@/services/jobs/setResponse';
import { getNewAccordionAfterCategoryClick } from '@/services/jsonInterface/accordion';
import { END_ENTITIES_STEP, START_ENTITIES_STEP } from '@/services/text/constants';
import {
  getMidsToHideWhenSelectingRelation,
  notificationMessageAllowed,
  notificationMessageNoDuplicates,
} from '@/services/text/helpers';
import { type AppThunk, store } from '@/store';
import {
  labelInterfaceUpdateField,
  removeAllSelectedObjectIds,
  replaceAllWithSelectedObjectId,
  useStore,
} from '@/zustand';
import { selectToolOptions } from '@/zustand/label-interface/selectors';

import {
  getAnnotationFromMid,
  getAnnotationFromMidAndAnnotations,
  getMlTaskFromJobName,
} from './helpers';
import {
  jobsAnnotations,
  jobsCurrentMlTask,
  jobsObjectChildrenResponse,
  jobsResponses,
  jobsRotation,
} from './selectors';
import {
  JOBS_ADD_ANNOTATION,
  JOBS_INITIALIZE,
  JOBS_REMOVE_ANNOTATIONS,
  JOBS_SET_CLASSIFICATION_RESPONSE,
  JOBS_SET_CURRENT_FRAME_RESPONSE,
  JOBS_SET_RESPONSE,
  JOBS_SET_RESPONSE_AT_PAGE_LEVEL,
  JOBS_SET_RESPONSES,
  JOBS_UPDATE_ANNOTATIONS,
} from './slice';
import {
  type ChangeClassPayload,
  type EndRelationPayload,
  type JobsAddAnnotationPayload,
  type JobsRemoveAnnotationsPayload,
  type JobsSetResponseClassificationAtPageLevelPayload,
  type JobsSetResponseClassificationCleanPayload,
  type JobsSetResponseDefaultPayload,
  type JobsSetResponsesPayload,
  type JobsSetResponseSpeechToTextPayload,
  type JobsSetResponseTranscriptionAtPageLevelPayload,
  type JobsSetResponseTranscriptionPayload,
  type JobsUpdateAnnotationsPayload,
  type RelationAnnotations,
  type StartRelationPayload,
} from './types';

import { availableClassesToChange } from '../../services/jobs/changeClass';
import { addNotification } from '../application/actions';
import {
  initializeState as initializeJobState,
  setCurrentJobName,
  setSelectedCategory,
} from '../job/actions';
import { jobCurrentCategories, jobCurrentJobName } from '../job/selectors';
import { type FlatTree } from '../project/ontologyTree';
import { projectTreeFlat } from '../project/selectors';
import { projectInputType, projectJobs, projectJsonInterface } from '../selectors';
import { type State } from '../types';

export type ActionDefaultReturn = { payload: JobsSetResponseDefaultPayload; type: string };
type ActionResponsesReturn = { payload: JobsSetResponsesPayload; type: string };

export const initializeState = (): {
  payload: undefined;
  type: string;
} => {
  return JOBS_INITIALIZE();
};

export const toggleAccordionAfterCategoryClick = (
  job: [string, Job],
  category: [string, Category],
  path: string[],
  parentMid?: string,
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { accordionTree } = useStore.getState().labelInterface;
    const input = job[1]?.content?.input;
    const shouldCloseClickedCategory =
      input === Input.CHECKBOX &&
      !isClassificationCategoryChecked(state, { category, job, parentMid, path, recursion: 0 });
    const shouldCloseObjects = job[1]?.mlTask !== MachineLearningTask.CLASSIFICATION;
    const newAccordion = getNewAccordionAfterCategoryClick(
      job,
      category,
      accordionTree,
      path,
      shouldCloseClickedCategory,
      shouldCloseObjects,
    );
    labelInterfaceUpdateField({ path: 'accordionTree', value: newAccordion });
  };
};

export const initializeHistory = (): AppThunk => {
  return async dispatch => {
    dispatch(ActionCreators.clearHistory());
  };
};

export const setResponses = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse,
): ActionResponsesReturn => {
  const responsesToSet = getResponsesToSet(jobs, labelType, responses);
  return JOBS_SET_RESPONSES({ responsesToSet, shouldUseInitialState: true });
};

export const setCurrentFrameResponse = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse,
): ActionResponsesReturn => {
  const responsesToSet = getResponsesToSet(jobs, labelType, responses);
  return JOBS_SET_CURRENT_FRAME_RESPONSE({ responsesToSet });
};

export const addAnnotation = (
  payload: JobsAddAnnotationPayload,
): { payload: JobsAddAnnotationPayload; type: string } => {
  return JOBS_ADD_ANNOTATION(payload);
};

export const removeAnnotations = (
  mids: string[],
): { payload: JobsRemoveAnnotationsPayload; type: string } => {
  const state = store.getState();
  const annotations = jobsAnnotations(state) as KiliAnnotation[];
  const jobs = projectJobs(state);

  const annotationsToRemove = mids
    .map(mid => getAnnotationFromMidAndAnnotations(mid, annotations))
    .filter((annotation): annotation is KiliAnnotation => !!annotation)
    .map(annotation => {
      const { jobName, mid } = annotation;
      const mlTask = annotation.mlTask || getMlTaskFromJobName(jobs, jobName);

      if (mlTask === undefined) {
        throw new Error(`Unable to find mlTask of annotation to delete with mid ${mid}`);
      }

      const objectParts: string[] = Object.hasOwn(annotation, 'allPoints')
        ? (annotation as KiliAnnotation & { allPoints: PoseEstimationPoint[] }).allPoints.map(
            point => point.code,
          )
        : [];

      return {
        jobName,
        mid,
        mlTask,
        objectParts,
      };
    });

  return JOBS_REMOVE_ANNOTATIONS({ annotationsToRemove });
};

export const setNamedEntitiesRecognitionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.NAMED_ENTITIES_RECOGNITION,
    parentMid: payload.parentMid,
  });
};

export const setObjectDetectionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    parentMid: payload.parentMid,
  });
};

export const setPoseEstimationResponse = (
  payload: JobsSetResponseDefaultPayload,
): { payload: JobsSetResponsesPayload; type: string } => {
  const responsesToSet = [
    {
      jobName: payload.jobName,
      jobResponse: payload.jobResponse,
      mlTask: MachineLearningTask.POSE_ESTIMATION,
      parentMid: payload.parentMid,
    },
    {
      jobName: payload.jobName,
      jobResponse: payload.jobResponse,
      mlTask: MachineLearningTask.OBJECT_DETECTION,
      parentMid: payload.parentMid,
    },
  ];
  return JOBS_SET_RESPONSES({ responsesToSet, shouldUseInitialState: false });
};

export const setSpeechToTextResponse = (
  payload: JobsSetResponseSpeechToTextPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.SPEECH_TO_TEXT,
    parentMid: payload.parentMid,
  });
};

export const setTranscriptionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.TRANSCRIPTION,
    parentMid: payload.parentMid,
  });
};

export const setAutoTranscriptionResponseFromAnnotation = (payload: {
  annotation: { boundingPoly: ImageBoundingPoly };
  jobNames: string[];
  parentMid: string;
  text: string;
}): AppThunk => {
  return async (dispatch, getState) => {
    const { annotation, jobNames, text, parentMid } = payload;
    const state = getState();
    const rotation = jobsRotation(state);
    const currentObjectResponse = jobsObjectChildrenResponse(state, parentMid);
    const { boundingPoly } = annotation;
    const rotatedAnnotation = rotateAnnotationByAngle(rotation, {
      boundingPoly: [boundingPoly],
    } as ObjectAnnotation2D) as ObjectAnnotation2D;
    if (!rotatedAnnotation.boundingPoly) {
      return;
    }

    jobNames.forEach(jobName => {
      const currentText = currentObjectResponse?.TRANSCRIPTION?.[jobName];
      const shouldChangeTranscription = !currentText;
      if (shouldChangeTranscription) {
        dispatch(
          setTranscriptionResponse({
            jobName,
            jobResponse: { text },
            parentMid,
          }),
        );
      }
    });
  };
};

export const updateClassificationResponseAndClean = (
  payload: JobsSetResponseClassificationCleanPayload,
): ActionDefaultReturn => {
  return JOBS_SET_CLASSIFICATION_RESPONSE({
    jobName: payload.jobName,
    jobResponse: {
      categories: payload.categoryCodes.map(categoryCode => ({
        confidence: 100,
        name: categoryCode,
      })),
    },
    jobsToClean: payload.jobsToClean,
    parentMid: payload.parentMid,
  });
};

export const updateBasicClassificationAtPageLevelResponse = (
  payload: JobsSetResponseClassificationAtPageLevelPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE_AT_PAGE_LEVEL({
    jobName: payload.jobName,
    jobResponse: {
      categories: payload.categoryCodes.map(categoryCode => ({
        confidence: 100,
        name: categoryCode,
      })),
      page: payload.pageNumber,
    },
    mlTask: MachineLearningTask.PAGE_LEVEL_CLASSIFICATION,
    pageNumber: payload.pageNumber,
  });
};

export const updateResponseForAnnotations = (
  payload: JobsUpdateAnnotationsPayload,
): { payload: JobsUpdateAnnotationsPayload; type: string } => {
  return JOBS_UPDATE_ANNOTATIONS({
    annotations: payload.annotations,
    mlTask: payload.mlTask,
    noHistory: payload?.noHistory,
  });
};

export const updateBasicTranscriptionResponse = (
  payload: JobsSetResponseTranscriptionPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: {
      text: payload.text,
    },
    mlTask: MachineLearningTask.TRANSCRIPTION,
    parentMid: payload.parentMid,
  });
};

export const updateBasicTranscriptionAtPageLevelResponse = (
  payload: JobsSetResponseTranscriptionAtPageLevelPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE_AT_PAGE_LEVEL({
    jobName: payload.jobName,
    jobResponse: {
      page: payload.pageNumber,
      text: payload.text,
    },
    mlTask: MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION,
    pageNumber: payload.pageNumber,
  });
};

export const updateTranscriptionAtPageLevelResponse = ({
  jobName,
  pageNumber,
  text,
}: {
  jobName: string;
  pageNumber: number;
  text: string;
}): AppThunk => {
  return async dispatch => {
    dispatch(updateBasicTranscriptionAtPageLevelResponse({ jobName, pageNumber, text }));
  };
};

export const updateTranscriptionResponse = ({
  jobName,
  parentMid,
  text,
}: {
  jobName: string;
  parentMid?: string;
  text: string;
}): AppThunk => {
  return async dispatch => {
    dispatch(
      updateBasicTranscriptionResponse({
        jobName,
        parentMid,
        text,
      }),
    );
  };
};

const getJobsToClean = ({
  initialCategoryCodes,
  finalCategoryCodes,
  treeFlat,
  jobName,
}: {
  finalCategoryCodes: string[];
  initialCategoryCodes: string[];
  jobName: string;
  treeFlat: FlatTree;
}) => {
  const jobUnselectedCategories = initialCategoryCodes.filter(
    code => !finalCategoryCodes.includes(code),
  );
  const categoryNodesToClean = jobUnselectedCategories
    .map(code => treeFlat?.[jobName]?.[code])
    .flat();
  const jobsToClean = _uniqBy(
    categoryNodesToClean.filter(node => !!node && node?.jobName !== jobName),
    node => node.jobName,
  );

  return jobsToClean;
};

export const updateClassificationResponse = ({
  categoryCodes,
  jobName,
  parentMid,
}: {
  categoryCodes: string[];
  jobName: string;
  parentMid: string | undefined;
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    const treeFlat = projectTreeFlat(state);
    const stateJobs = projectJobs(state);

    const { isSelected: isSegmentationToolSelected } = selectToolOptions('Segmentation')(
      useStore.getState(),
    );

    let jobNameToUse = jobName;
    if (isSegmentationToolSelected) {
      const models = (stateJobs?.[jobName]?.models ?? {}) as ImageModel;
      jobNameToUse = models?.[ModelName.INTERACTIVE_SEGMENTATION]?.job ?? '';
    }

    const { mlTask, content } = stateJobs[jobNameToUse] ?? {};
    batch(() => {
      const isAudio = inputType === InputType.AUDIO;
      if (mlTask === MachineLearningTask.CLASSIFICATION || isAudio) {
        if (isAudio) {
          dispatch(setCurrentJobName(jobNameToUse));
        }
        const jobCategoryCodes = Object.keys(content?.categories ?? {});
        const jobsToClean = getJobsToClean({
          finalCategoryCodes: categoryCodes,
          initialCategoryCodes: jobCategoryCodes,
          jobName: jobNameToUse,
          treeFlat,
        });

        dispatch(
          updateClassificationResponseAndClean({
            categoryCodes,
            jobName: jobNameToUse,
            jobsToClean,
            parentMid,
          }),
        );
      }
    });
  };
};

export const updateClassificationAtPageLevelResponse = ({
  categoryCodes,
  jobName,
  pageNumber,
}: {
  categoryCodes: string[];
  jobName: string;
  pageNumber: number;
}): AppThunk => {
  return async dispatch => {
    batch(() => {
      dispatch(
        updateBasicClassificationAtPageLevelResponse({
          categoryCodes,
          jobName,
          pageNumber,
        }),
      );
    });
  };
};

export const changeClassVideo = (payload: ChangeClassPayload): AppThunk => {
  return dispatch => {
    const { jobName, categoryCode } = payload;
    const { labelFrame, labelInterface } = useStore.getState();
    const { selectedObjectIds } = labelInterface;
    const { incrementJobCategoryAnnotationCount } = labelFrame;
    const jobsInterface = projectJobs(store.getState());
    const job = jobsInterface[jobName];
    const category = jobsInterface[jobName].content.categories?.[categoryCode];
    const name = category?.name;
    const path = [jobName, 'categories', categoryCode];

    if (category === undefined || name === undefined) {
      throw new Error('Invalid category');
    }

    batch(() => {
      dispatch(initializeJobState());
      dispatch(toggleAccordionAfterCategoryClick([jobName, job], [categoryCode, category], path));

      const { ANNOTATION_JOB_COUNTER } = useStore.getState().labelFrame.jobCategoriesToMids;
      const categoryCurrentCounter = ANNOTATION_JOB_COUNTER[jobName]?.[categoryCode] ?? 0;

      incrementJobCategoryAnnotationCount({
        category: categoryCode,
        increment: selectedObjectIds.length,
        jobName,
      });
      changeAnnotationsClassVideoSplitWithHistory({
        categoryCode,
        categoryCurrentCounter,
        jobName,
        mids: selectedObjectIds,
      });

      removeAllSelectedObjectIds();
    });
  };
};

const handleStartRelation = (payload: StartRelationPayload): AppThunk => {
  return (dispatch, getState) => {
    const {
      isImageProject,
      job,
      jobName,
      mid,
      responses,
      selectedCategory,
      creatingOrEditingObjectId,
      jsonInterface,
    } = payload;

    const mlTask = isImageProject
      ? MachineLearningTask.OBJECT_RELATION
      : MachineLearningTask.NAMED_ENTITIES_RELATION;

    const newAnnotation = isImageProject
      ? {
          categories: selectedCategory ? [{ name: selectedCategory.name }] : [],
          endObjects: [],
          jobName,
          mid: creatingOrEditingObjectId,
          mlTask,
          startObjects: [{ mid }],
        }
      : {
          categories: selectedCategory ? [{ name: selectedCategory.name }] : [],
          endEntities: [],
          jobName,
          mid: creatingOrEditingObjectId,
          mlTask,
          startEntities: [{ mid }],
        };

    if (jobName && selectedCategory) {
      dispatch(
        addAnnotation({
          annotation: newAnnotation,
          mlTask,
          noHistory: true,
        }),
      );
      const selectedRelation = selectedCategory?.name;
      const midsToHide = getMidsToHideWhenSelectingRelation(
        selectedRelation,
        job,
        responses,
        END_ENTITIES_STEP,
        [{ mid }],
      );
      labelInterfaceUpdateField({
        path: 'hiddenObjectIds',
        value: midsToHide,
      });
    } else {
      labelInterfaceUpdateField({
        path: 'temporaryAnnotations',
        value: [newAnnotation],
      });
      const availableClasses = availableClassesToChange({
        allowCurrentCategory: true,
        annotations: [newAnnotation],
        jsonInterface,
      });
      if (availableClasses.length === 1) {
        const newAnnotationFilledWithCategoryAndJobName = {
          ...newAnnotation,
          categories: [{ name: availableClasses[0].categoryCode }],
          jobName: availableClasses[0].jobName,
        };

        dispatch(
          addAnnotation({
            annotation: newAnnotationFilledWithCategoryAndJobName,
            mlTask,
            noHistory: true,
          }),
        );
        dispatch(
          setSelectedCategory(availableClasses[0].jobName, availableClasses[0].categoryCode),
        );
        const midsToHide = getMidsToHideWhenSelectingRelation(
          newAnnotationFilledWithCategoryAndJobName.categories?.[0].name,
          jsonInterface.jobs?.[availableClasses[0].jobName],
          jobsResponses(getState()),
          END_ENTITIES_STEP,
          [
            {
              mid:
                mlTask === MachineLearningTask.OBJECT_RELATION
                  ? (newAnnotationFilledWithCategoryAndJobName as ObjectRelation).startObjects?.[0]
                      ?.mid
                  : (newAnnotationFilledWithCategoryAndJobName as EntityRelation).startEntities?.[0]
                      ?.mid,
            },
          ],
        );
        labelInterfaceUpdateField({
          path: 'hiddenObjectIds',
          value: midsToHide,
        });
      }
    }
  };
};

const handleEndRelation = (payload: EndRelationPayload): AppThunk => {
  return dispatch => {
    const { isImageProject, jobName, mid, relationAnnotation } = payload;
    const newAnnotation = isImageProject
      ? {
          ...relationAnnotation,
          endObjects: ((relationAnnotation as ObjectRelation)?.endObjects ?? []).concat({ mid }),
        }
      : {
          ...relationAnnotation,
          endEntities: ((relationAnnotation as EntityRelation)?.endEntities ?? []).concat({ mid }),
        };
    const mlTask = isImageProject
      ? MachineLearningTask.OBJECT_RELATION
      : MachineLearningTask.NAMED_ENTITIES_RELATION;
    dispatch(
      updateResponseForAnnotations({
        annotations: [{ ...newAnnotation, jobName, mlTask }],
        mlTask,
      }),
    );
  };
};

type RelationStep = 'START_ENTITIES_STEP' | 'END_ENTITIES_STEP';

const getRelationObjectType = (
  isImageProject: boolean,
  relationStep: RelationStep,
): RelationObjectType => {
  if (relationStep === START_ENTITIES_STEP)
    return isImageProject ? RelationObjectType.START_OBJECTS : RelationObjectType.START_ENTITIES;
  return isImageProject ? RelationObjectType.END_OBJECTS : RelationObjectType.END_ENTITIES;
};

type IsSelectingWrongObjectForRelationArgs = {
  annotation: KiliAnnotation;
  dispatch: ThunkDispatch<Readonly<State>, unknown, Action<string>>;
  isImageProject: boolean;
  job: Job;
  mid: string;
  relationAnnotation?: ObjectRelation | EntityRelation;
  relationStep: RelationStep;
  selectedCategory: AnnotationCategory;
};

const isSelectingWrongObjectForRelation = ({
  dispatch,
  annotation,
  job,
  mid,
  isImageProject,
  relationAnnotation,
  selectedCategory,
  relationStep,
}: IsSelectingWrongObjectForRelationArgs) => {
  const allowedCategories = job?.content?.categories ?? {};
  const categoryCode = getCategoryCodeFromAnnotation(annotation);
  const relationObjectType = getRelationObjectType(isImageProject, relationStep);
  const allowedObjects = getAllowedObjects(allowedCategories, selectedCategory, relationObjectType);
  if (!allowedObjects.includes(ANY_RELATION_VALUE) && allowedObjects.indexOf(categoryCode) === -1) {
    dispatch(addNotification(notificationMessageAllowed(allowedObjects, false)));
    return true;
  }
  if (relationAnnotation && relationStep === END_ENTITIES_STEP) {
    if (isImageProject) {
      const objectRelationAnnotation = relationAnnotation as ObjectRelation;
      if (objectRelationAnnotation.endObjects.find(ann => ann.mid === mid)) {
        dispatch(addNotification(notificationMessageNoDuplicates(false)));
        return true;
      }
    } else {
      const nerRelationAnnotation = relationAnnotation as EntityRelation;
      if (nerRelationAnnotation.endEntities.find(ann => ann.mid === mid)) {
        dispatch(addNotification(notificationMessageNoDuplicates(true)));
        return true;
      }
    }
  }
  return false;
};

export const handleRelation = (mid: string): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();

    const { creatingOrEditingObjectId } = useStore.getState().labelInterface;

    const responses = jobsResponses(state);

    const annotation = getAnnotationFromMid(mid, responses);
    if (!annotation) throw new Error('Annotation not found');

    const inputType = projectInputType(state);
    const isImageProject = inputType === InputType.IMAGE;

    const currentMlTask = jobsCurrentMlTask(state);
    const currentJobName = jobCurrentJobName(state);
    const relationAnnotation = (
      responses?.[currentMlTask]?.[currentJobName] as RelationAnnotations | undefined
    )?.annotations?.find(ann => ann.mid === creatingOrEditingObjectId);
    const relationStep = !relationAnnotation ? START_ENTITIES_STEP : END_ENTITIES_STEP;

    const jsonInterface = projectJsonInterface(state);

    const selectedCategory = jobCurrentCategories(state)?.[0];

    const job = jsonInterface?.jobs?.[currentJobName];

    if (
      currentJobName &&
      isSelectingWrongObjectForRelation({
        annotation,
        dispatch,
        isImageProject,
        job,
        mid,
        relationAnnotation,
        relationStep,
        selectedCategory,
      })
    )
      return;

    if (relationStep === START_ENTITIES_STEP && creatingOrEditingObjectId) {
      dispatch(
        handleStartRelation({
          creatingOrEditingObjectId,
          isImageProject,
          job,
          jobName: currentJobName,
          jsonInterface,
          mid,
          responses,
          selectedCategory,
        }),
      );
      replaceAllWithSelectedObjectId({ mid: creatingOrEditingObjectId });
    }

    if (relationStep === END_ENTITIES_STEP && relationAnnotation) {
      dispatch(
        handleEndRelation({
          isImageProject,
          jobName: currentJobName,
          mid,
          relationAnnotation,
        }),
      );
    }
  };
};
